const radio = [
  {
    id: "12570",
    hts: "01",
    title: "Rid Rádio",
    url: "https://player-ssl.kshost.com.br:",
    url_radio: "https://hts01.brascast.com:12570/live",
    param: "live",
  },
  {
    id: "12636",
    hts: "07",
    title: "Paradas de Sucesso",
    url: "https://player-ssl.kshost.com.br:",
    url_radio: "https://hts07.brascast.com:12636/live",
    param: "live",
  },
  {
    id: "12330",
    hts: "07",
    title: "Rádio Lounge",
    url: "https://player-ssl.kshost.com.br:",
    url_radio: "https://hts07.brascast.com:12330/live",
    param: "live",
  },
  {
    id: "9782",
    hts: "03",
    title: "Rádio Gospel",
    url: "https://player-ssl.kshost.com.br:",
    url_radio: "https://hts03.brascast.com:9782/live",
    param: "live",
  },
];
export default radio;

export const repeatLocucao = [
  { minute: 7, text: "A cada 7 minutos" },
  { minute: 15, text: "A cada 15 minutos" },
  { minute: 23, text: "A cada 23 minutos" },
  { minute: 40, text: "A cada 40 minutos" },
];
